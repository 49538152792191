<template>
    <div>
        <!-- 搜索栏 -->
        <div class="searchContainer">
            <el-form :inline="true" :model="fileDataForm">
                <el-form-item>
                    <el-select v-model="currentAnchorSecId" placeholder="输入主播名称筛选" size="mini" style="width: 170px;"
                        filterable clearable @change="anchorChange">
                        <el-option v-for="item in compereList" :key="item.SecUid" :label="item.AnchorName"
                            :value="item.SecUid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="录制时间">
                    <el-date-picker v-model="recordDate" type="daterange" range-separator="~" start-placeholder="开始时间"
                        end-placeholder="结束时间" align="right" size="mini" style="width: 200px;"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="分析时间">
                    <el-date-picker v-model="analysisDate" type="daterange" range-separator="~" start-placeholder="开始时间"
                        end-placeholder="结束时间" align="right" size="mini" style="width: 200px;"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <el-button size="mini" type="primary" plain @click="search">查找</el-button>
        </div>
        <!-- 对比 -->
        <div v-if="contrastList && contrastList.length > 0" style="display: flex; align-items: center;">
            <el-tag v-for="tag in contrastList" :key="tag.VideoId" closable style="margin-right: 10px;"
                @close="cancelContrast(tag)">
                <!-- {{ tag.AnchorInfo.AnchorName + "(" + tag.VideoName + ")" }} -->
                {{ tag.VideoName }}
            </el-tag>
            <el-button size="small" type="primary" @click="contrastSubmit">开始对比</el-button>
        </div>
        <!-- 视频列表 -->
        <div>
            <!-- 列表标题 -->
            <div class="listTitleContainer">
                <div style="flex: 1;">主播</div>
                <div style="flex: 1.5;">视频文件</div>
                <div style="flex: 1.2;text-align: center;">录制时间</div>
                <div style="flex: 1;text-align: center;">大小/时长</div>
                <div style="flex: 0.8;text-align: center;">分析时间</div>
                <div style="width: 100px;text-align: center;">操作</div>
            </div>
            <!-- 列表 -->
            <div class="tableContainer">
                <div v-for="(item, index) in fileList" :key="item.id" class="listRowContainer"
                    :style="index % 2 != 0 ? 'background: rgb(245,247,250)' : ''">
                    <div class="compereColContainer">
                        <img :src="item.AnchorInfo.AnchorAvatar" class="compereImg">
                        <div class="compereInfoContainer">
                            <div class="compereName">{{ item.AnchorInfo.AnchorName }}</div>
                            <div class="compereStatusContainer">
                                <div v-if="item.AnchorInfo.LiveStatus == 0" class="compereNotPlayStatus">未检测</div>
                                <div v-if="item.AnchorInfo.LiveStatus == -1" class="compereNotPlayStatus">检测中</div>
                                <div v-if="item.AnchorInfo.LiveStatus == 4" class="compereNotPlayStatus">未开播</div>
                                <div v-if="item.AnchorInfo.LiveStatus == 2" class="comperePlayStatus">
                                    <img src="@/assets/imgs/play.png" class="comperePlayStatusImg">
                                    <div class="comperePlayStatusText">直播中</div>
                                </div>
                                <div class="compereResourceType"
                                    v-if="item.AnchorInfo.AnchorPlatform == 'DouYinHomeLive' || item.AnchorInfo.AnchorPlatform == 'DouYinLive'">
                                    抖音</div>
                                <div class="compereResourceType" v-if="item.AnchorInfo.AnchorPlatform == 'TiktokLive'">
                                    tiktok</div>
                                <div class="compereResourceType"
                                    v-if="item.AnchorInfo.AnchorPlatform == 'KuaiShouLive'">快手</div>
                            </div>
                        </div>
                    </div>

                    <div class="fileColContainer">
                        <img src="@/assets/imgs/video.png" class="videoImg">
                        <div class="fileNameContainer">
                            <div class="videoNameText">{{ item.VideoName }}</div>
                            <div class="compereNameText">{{ item.LiveTitle }}</div>
                        </div>
                    </div>
                    <div class="recordColContainer">
                        <div>{{ item.StartTime.substring(0, 16) }}</div>
                        <div style="line-height: 0.8;">~</div>
                        <div>{{ item.EndTime.substring(0, 16) }}</div>
                    </div>
                    <div class="fileSizeColContainer" style="flex-direction: column;">
                        <div>{{ item.VedioSizie + "M" }}</div>
                        <div>{{ item.DurationStr }}</div>
                    </div>
                    <div class="analysisDateColContainer">
                        <div>{{ item.AnalysisTime.substring(0, 10) }}</div>
                        <div>{{ item.AnalysisTime.substring(10) }}</div>
                    </div>
                    <div class="operateColContainer">
                        <div class="analysisContainer">
                            <div class="lookAnalysis" @click="lockAnalysis(item.VideoId)">查看直播分析</div>
                        </div>
                        <div class="operateBtnContainer">
                            <div class="operateBtn" @click="preview(item.VideoId)">预览</div>
                            <div class="operateBtn" @click="addContrast(item)" v-if="!item.isContrast">加入对比</div>
                            <div class="contrastBtn" @click="cancelContrast(item)" v-else>取消对比</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 分页 -->
            <div style="position: absolute; bottom: 16px;left: 0;right: 0;">
                <el-pagination style="text-align: center; margin-top: 10px" @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import myUtils from '@/utils/utils.js';
export default {
    name: 'ReplayClientAnalysisFinishList',

    data() {
        return {
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            recordDate: [],
            analysisDate: [],
            resourceTypeList: [
                { value: 0, label: "抖音" },
                { value: 1, label: "快手" },
            ],
            contrastList: [], // 对比列表
            fileList: [], // 视频列表
            compereList: [],
            currentAnchorSecId: -1,
            fileDataForm: {
                pageIndex: 1,
                pageSize: 10,
                secUid: -1,
                analysisStatus: 2,
                recordStartDate: "",
                recordEndDate: "",
                analysisStartDate: "",
                analysisEndDate: "",
            }
        };
    },

    mounted() {
        this.getVideoList();
        this.getCompereList();
    },

    methods: {
        // 预览
        preview(videoId) {
            this.$httpClient.video.preview({ videoId }).then((res) => {
                if (res.code == 0) {
                    window.open(res.data, "_blank");
                }
            });
        },
        // 查看分析结果
        lockAnalysis(id) {
            this.$store.commit("saveVideoAnalysisId", id);
            this.toPage("analysis");
        },
        // 提交对比
        contrastSubmit() {
            if (!this.contrastList || this.contrastList.length != 2) {
                this.$message.error("请选择两个进行对比");
                return;
            }

            let requestData = {
                VideoOneId: this.contrastList[0].VideoId,
                VideoTwoId: this.contrastList[1].VideoId,
                AnchorOneId: this.contrastList[0].SecUid,
                AnchorTwoId: this.contrastList[1].SecUid,
            }

            this.$httpClient.video.savecontrast(requestData).then(res => {
                if (res.code == 0) {
                    this.fileList.forEach(item => {
                        item.isContrast = false;
                    });
                    this.contrastList = [];
                    this.toMenu("compare");
                }
            })


        },
        // 取消对比
        cancelContrast(data) {
            this.contrastList = this.contrastList.filter(item => item.VideoId != data.VideoId);

            this.fileList.forEach(item => {
                if (item.VideoId == data.VideoId) {
                    item.isContrast = false;
                }
            });
        },
        // 加入对比
        addContrast(data) {
            if (this.contrastList && this.contrastList.length >= 2) {
                this.$message.error("仅支持两个对比");
                return;
            }
            this.fileList.forEach(item => {
                if (item.VideoId == data.VideoId) {
                    item.isContrast = true;
                }
            });
            this.contrastList.push(data);
        },
        anchorChange(value) {
            if (value === "") {
                this.currentAnchorSecId = -1;
            }
        },
        search() {
            this.pageIndex = 1;
            this.getVideoList();
        },
        // 获取主播列表
        getCompereList() {
            this.compereList = [];
            let requestData = {
                pageIndex: 1,
                pageSize: 500,
                anchorName: "",
                recordStatus: -1
            }
            this.$httpClient.compere.getpageanchor(requestData).then((res) => {
                if (res.code == 0) {
                    this.compereList.push({
                        SecUid: -1,
                        AnchorName: "全部"
                    });
                    if (res.data.DataList && res.data.DataList.length > 0) {
                        res.data.DataList.forEach(item => {
                            this.compereList.push(item);
                        })
                    }
                    this.currentAnchorSecId = -1;
                }
            })
        },
        // 获取主播视频列表
        getVideoList() {
            this.fileList = [];
            this.fileDataForm = {
                pageIndex: 1,
                pageSize: 10,
                secUid: -1,
                analysisStatus: 2,
                recordStartDate: "",
                recordEndDate: "",
                analysisStartDate: "",
                analysisEndDate: "",
            };
            this.fileDataForm.pageIndex = this.pageIndex;
            this.fileDataForm.pageSize = this.pageSize;
            this.fileDataForm.secUid = this.currentAnchorSecId;

            if (this.recordDate && this.recordDate.length > 0) {
                this.fileDataForm.recordStartDate = this.recordDate[0];
                this.fileDataForm.recordEndDate = this.recordDate[1];
            }
            if (this.analysisDate && this.analysisDate.length > 0) {
                this.fileDataForm.analysisStartDate = this.analysisDate[0];
                this.fileDataForm.analysisEndDate = this.analysisDate[1];
            }

            this.$httpClient.video.getpage(this.fileDataForm).then((res) => {
                if (res.code == 0) {
                    this.totalCount = res.data.Total;
                    this.fileList = res.data.DataList;
                    if (this.fileList && this.fileList.length > 0) {
                        this.fileList.forEach(item => {
                            item.Duration = myUtils.toformatTime(item.Duration * 1000);
                            item.VedioSizie = myUtils.retainDecimals(item.VedioSizie / 1024 / 1024);
                            // 计算时长
                            let second = myUtils.toSecondByDate(item.EndTime) - myUtils.toSecondByDate(item.StartTime);
                            item.DurationStr = myUtils.toformatTimeChinse(second * 1000);
                        })
                    }
                }
            })
        },
        toPage(url) {
            this.$emit("toPage", url);
        },
        toMenu(menu) {
            this.$emit("toMenu", menu);
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getVideoList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getVideoList();
        },
    },
};
</script>

<style scoped lang="less">
.contrastBtn {
    height: 20px;
    border-radius: 4px;
    border: 1px solid #0077FF;
    font-size: 12px;
    color: #0077FF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 4px;
}

.operateBtn {
    height: 20px;
    border-radius: 4px;
    border: 1px solid #B4BCCA;
    font-size: 12px;
    color: #2E3742;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 4px;
}

.operateBtnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.lookAnalysis {
    width: 98px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #0077FF;
    font-size: 12px;
    color: #0077FF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.analysisContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.operateText {
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
}

.operateColContainer {
    display: flex;
    flex-direction: column;
    width: 100px;
    justify-content: center;
}

.fileSizeColContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
}

.analysisDateColContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0.8;
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
}

.recordColContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1.2;
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
}

.compereNameText {
    font-weight: 400;
    font-size: 13px;
    color: #677583;
    margin-top: 4px;
}

.videoNameText {
    font-weight: 400;
    font-size: 14px;
    color: #2E3742;
}

.fileNameContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.videoImg {
    width: 40px;
    height: 40px;
}

.fileColContainer {
    display: flex;
    align-items: center;
    flex: 1.5;
}

.comperePlayStatusText {
    margin-left: 4px;
    font-weight: 500;
    font-size: 13px;
    color: #FF3270;
}

.comperePlayStatusImg {
    width: 18px;
}

.comperePlayStatus {
    display: flex;
    align-items: center;
}

.compereResourceType {
    background: #200B1C;
    border-radius: 8px;
    font-size: 10px;
    color: #FFFFFF;
    text-align: center;
    height: 15px;
    line-height: 15px;
    padding: 0 8px;
    margin-left: 5px;
}

.compereNotPlayStatus {
    font-size: 13px;
    color: #95A1AF;
}

.compereStatusContainer {
    display: flex;
    align-items: center;
}

.compereName {
    font-size: 14px;
    color: #2E3742;
}

.compereInfoContainer {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.compereImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.compereColContainer {
    flex: 1;
    display: flex;
    align-items: center;
}

.listRowContainer {
    display: flex;
    align-items: center;
    padding: 10px 13px;
}

.tableContainer::-webkit-scrollbar {
    display: none;
}

.tableContainer {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.listTitleContainer {
    font-weight: 500;
    font-size: 12px;
    color: #2E3742;
    height: 28px;
    background: #F5F7F9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 13px;
    margin-top: 4px;
}

.searchContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

/deep/ .el-form-item {
    margin-bottom: 0px;
}

/deep/ .el-range-separator {
    width: 10%;
}
</style>